import { Injectable } from '@angular/core';
import { TableFilterItem } from '@shared/fleet-table/interfaces/data-query-service.interface';
import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { TablePaginationDto } from '@shared/dto/table-pagination.dto';
import { environment } from '@environments/environment';
import { FleetVehicleMainInfo } from '@app/pages/cars/store/interfaces/fleet-vehicle-main-info.interface';
import { FleetVehicleDriver } from '@app/pages/cars/store/interfaces/fleet-vehicle-driver.interface';
import { FleetInsurance } from '../../insurances/store/interfaces/fleet-insurance.interface';
import { Observable } from 'rxjs';
import { FleetVehiclesTotalResponseDto } from './dto/fleet-vehicles-total.response.dto';
import { DDDLayoutQueryService } from '@app/core/ddd-layout/services/ddd-layout-query.service';
import { map, tap } from 'rxjs/operators';
import { emailEquals, insuranceStatusIn, typeInsuranceEquals } from '@app/pages/insurances/store/filters';
import { DDDEntity } from '@app/core/ddd-layout/interfaces/ddd-entity.interface';
import { HelperUtil } from '@shared/helper.util';
import { DriverEntity } from '@app/pages/drivers/store/interfaces/driver-entity.interface';
import { ERole } from '@shared/app-roles.const';
import { ClientPayloadModel } from '@shared/client/models/client-payload.model';
import { DDDEntitySelectAggregator, DDDEntitySelectSorting } from '@app/core/ddd-layout/dto/ddd-entity-select.request.dto';
import { EInsuranceStatus } from '@app/pages/insurances/store/enums/insurance-status.enum';


@Injectable()
export class CarsQueryService {
    urlVehicleService = environment.urlCustomerService + '/fleet/vehicle';
    urlVehiclesService = environment.urlCustomerService + '/fleet/vehicles';

    constructor(
        private httpClient: HttpClient,
        private readonly dddQueryService: DDDLayoutQueryService
    ) {
    }

    async get(
        page: number,
        onPage: number = 15,
        search: string = '',
        filters: TableFilterItem[] = [],
        sort: Sort = null): Promise<any> {
        const query: TablePaginationDto = {
            page,
            onPage,
            search,
            sort_field: sort?.active || 'id',
            sort_dir: sort?.direction || 'asc'
        };
        return this.httpClient.post(this.urlVehicleService, query).toPromise();
    }

    async getVehicleMainInfo(id: string): Promise<FleetVehicleMainInfo | any> {
        return this.httpClient.get(`${this.urlVehicleService}/${id}/main`).toPromise();
    }

    async getVehicleDrivers(id: string): Promise<FleetVehicleDriver[] | any> {
        return this.httpClient.get(`${this.urlVehicleService}/${id}/drivers`).toPromise();
    }

    getTotalVehicles(currentUsername: string): Observable<FleetVehiclesTotalResponseDto> {
        const result: Record<string, number> = {};

        return this.dddQueryService.getEntities('client', {
            aggregators: [
                {
                    type: 'car',
                },
            ],
            filters: {
                fields: [
                    emailEquals(currentUsername),
                ],
            },
        }).pipe(
            map(response => {
                const vehicles: DDDEntity[] = response.data.client?.[0]?.car ?? [];

                vehicles.filter(vehicle => HelperUtil.isValidPrimitive(vehicle.payload.model))
                    .forEach(vehicle => {
                        if (typeof result[vehicle.payload.model] !== 'number') {
                            result[vehicle.payload.model] = 0;
                        }

                        result[vehicle.payload.model] += 1;
                    });

                return Object.keys(result).map(model => ({
                    modelName: model,
                    count: result[model],
                }))
            })
        );
    }

    async getKaskoInsurancesByVehicle(id: string): Promise<FleetInsurance[] | any> {
        return this.httpClient.get(`${this.urlVehicleService}/${id}/kaskoInsurances`).toPromise();
    }

    async getOsagoInsurancesByVehicle(id: string): Promise<FleetInsurance[] | any> {
        return this.httpClient.get(`${this.urlVehicleService}/${id}/osagoInsurances`).toPromise();
    }

    activateVehicle(id: string): Observable<unknown> {
        const url = `${environment.urlCustomerService}/fleet/vehicle/${id}/activate`;
        return this.httpClient.post(url, {});
    }

    getByVin(vin: string): Observable<DDDEntity> {
        return this.dddQueryService.getEntities('car', {
            filters: {
                fields: [
                    {
                        fieldName: 'vin',
                        fieldType: 'string',
                        filterType: 'equals',
                        value: vin,
                    },
                ],
            },
            pagination: {
                onPage: 1,
                page: 0,
            },
        }).pipe(
            map(response => response.data.car?.[0])
        );
    }

    getCarDrivers(id: string): Observable<DriverEntity[]> {
        return this.dddQueryService.getEntities('car', {
            aggregators: [
                {
                    type: 'client',
                    aggregators: [
                        {
                            type: 'role',
                        },
                    ],
                },
            ],
            filters: {
                ids: [id],
            },
        }).pipe(
            map(response => response.data.car?.[0]?.client?.filter(client => client.role?.[0]?.payload?.ident === ERole.Driver) ?? []),
            map((drivers: DDDEntity[]) => drivers.map(entity => ({
                ...entity,
                payload: Object.assign(new ClientPayloadModel(), entity.payload),
            })))
        );
    }

    getCarActiveInsurance(id: string, type: 'КАСКО' | 'ОСАГО'): Observable<DDDEntity> {
        return this.dddQueryService.getEntities('car', {
            aggregators: [
                {
                    type: 'insurance',
                    filters: {
                        ids: [],
                        fields: [
                            typeInsuranceEquals(type),
                            insuranceStatusIn([EInsuranceStatus.Expires, EInsuranceStatus.Ok]),
                        ],
                    },
                    sorting: {
                        fieldName: 'endDateInsurance',
                        sortDirection: 'desc',
                    },
                },
            ],
            filters: {
                ids: [id],
            },
        }).pipe(
            map(response => response.data.car?.[0]?.insurance?.[0]),
        );
    }
}

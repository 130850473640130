import { SortDirection } from '@angular/material/sort';

export class DDDEntitySelectPagination {
    page: number = 10;
    onPage: number = 0;
}

export class DDDEntitySelectSorting {
    fieldName: string;
    sortDirection: SortDirection;
}

export class DDDBaseFilter {
    fieldType: string;
    fieldName: string;
    filterType: string;
    value: unknown;
}

export class DDDStringFilter extends DDDBaseFilter {
    fieldType: 'string';
    fieldName: string;
    filterType: 'equals' | 'like' | 'in';
    value: string;
}

export class DDDNumberFilter extends DDDBaseFilter {
    fieldType: 'number'
    fieldName: string;
    filterType: 'equals'
    value: number;
}

export class DDDEntitySelectFilter {
    ids?: string[];
    fields?: DDDBaseFilter[];
}

export class DDDEntitySelectAggregator {
    type: string;
    aggregators?: DDDEntitySelectAggregator[];
    filters?: {
        ids?: string[];
        fields?: DDDBaseFilter[];
    };
    pagination?: DDDEntitySelectPagination;
    sorting?: DDDEntitySelectSorting;
}

export class DDDEntitySelectRequestDto {
    aggregators?: DDDEntitySelectAggregator[];
    filters?: {
        ids?: string[];
        fields?: DDDBaseFilter[];
    };
    pagination?: DDDEntitySelectPagination;
    sorting?: DDDEntitySelectSorting;
}

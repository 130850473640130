import { Component, isDevMode, OnInit } from '@angular/core';
import { AuthGetProfileAction } from './core/auth/store/auth.actions';
import { Store } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { UpdaterComponent } from './core/updater/updater.component';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from "rxjs/operators";


@Component({
    selector: 'hml-root', template: '<hml-mobility-route-handler></hml-mobility-route-handler>',
})
export class AppComponent implements OnInit {
    private snackBarDNotOpen = true;

    constructor(
        private swUpdate: SwUpdate,
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private _snackBar: MatSnackBar) {
    }

    async ngOnInit() {
        this.swUpdate.available.subscribe(() => {
            this.openUpdateBar();
        });

        if (!isDevMode()) {
            setInterval(() => {
                this.swUpdate.checkForUpdate().then();
            }, 60000);
        }

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route: ActivatedRoute) => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            // так же мы выбираем только первичные аутлеты (опционально)
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
            mergeMap((route: ActivatedRoute) => route.data)
            //задаем
        ).subscribe(routerData => {
            this.titleService.setTitle(routerData?.title || 'Fleet');
        });

        await this.store.dispatch(new AuthGetProfileAction()).toPromise();
    }


    openUpdateBar() {
        this._snackBar.openFromComponent(UpdaterComponent, {
            panelClass: 'updateMessage',
            verticalPosition: 'bottom',
            horizontalPosition: 'start',
        });
        this.snackBarDNotOpen = false;
    }
}

import { TableFilterItem } from './data-query-service.interface';
import { Sort } from '@angular/material/sort';

export interface FleetTable {
    page: number;
    onPage: number;
    total: number;
    search: string;
    filters: TableFilterItem[];
    columns: string[];
    sort: any;
}

export const FLEET_TABLE_INITIAL = {
    page: 0,
    onPage: 15,
    total: 0,
    search: '',
    filters: [],
    columns: [],
    sort: null,
};

import { Injectable } from "@angular/core";
import { DDDEntity } from "@app/core/ddd-layout/interfaces/ddd-entity.interface";
import { DDDLayoutQueryService } from "@app/core/ddd-layout/services/ddd-layout-query.service";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ClientEntity } from "../interfaces/client-entity.interface";
import { ClientPayloadModel } from "../models/client-payload.model";

@Injectable()
export class ClientQueryService {
    constructor(
        private readonly dddQueryService: DDDLayoutQueryService
    ) { }

    public findOrCreateClient(payload: ClientPayloadModel): Observable<string> {
        return this.getByEmail(payload.email)
            .pipe(
                switchMap(client => client
                    ? of(client.id)
                    : this.dddQueryService.addEntity('client', payload).pipe(
                        map(response => response.data.newEntityId)
                    )
                )
            );
    }

    public getById(id: string): Observable<ClientEntity> {
        return this.dddQueryService.selectEntity('client', {
            aggregators: [],
            filters: {
                ids: [id],
                fields: [],
            },
        }).pipe(
            map(response => response.data.client?.[0]),
            map(entity => entity ? this.dddEntityToClient(entity) : null)
        );
    }

    public getByEmail(email: string): Observable<ClientEntity> {
        return this.dddQueryService.selectEntity('client', {
            aggregators: [],
            filters: {
                fields: [
                    {
                        fieldName: 'email',
                        fieldType: 'string',
                        filterType: 'equals',
                        value: email,
                    }
                ]
            }
        }).pipe(
            map(response => response.data.client?.[0]),
            map(entity => entity ? this.dddEntityToClient(entity) : null)
        );
    }

    protected dddEntityToClient(entity: DDDEntity): ClientEntity {
        const payload = Object.assign(new ClientPayloadModel(), entity.payload);

        return {
            ...entity,
            payload,
        }
    }
}

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import {
    AuthCheckImpersonationAction,
    AuthGetProfileAction,
    AuthGetUserRoleAction,
    AuthLoginAction,
    AuthLogoutAction,
    AuthRestorePasswordAction,
    AuthSendEmailRestorePasswordAction,
    ClearAuthRestorePasswordAction,
    ClearImpersonationAction,
    SetImpersonationAction,
} from './auth.actions';
import { AuthProfile } from '../auth.interfaces';
import { AuthQueryService } from '../services/auth-query.service';
import { catchError, tap } from 'rxjs/operators';
import { APP_URL_MAP } from '@app/core/app-url-map.';
import { MessageBarService } from '@shared/message-bar/message-bar.service';
import { HelperUtil } from '@shared/helper.util';
import { DDDEntity } from '@app/core/ddd-layout/interfaces/ddd-entity.interface';
import { RolePayload } from '@app/core/ddd-layout/interfaces/role-payload.interface';
import { Observable, throwError } from 'rxjs';

export interface AuthStateModel {
    accessToken: string;
    // токен настоящего юзера а не того кого подменили
    hostAccessToken: string;
    userId: string
    profile: AuthProfile;
    impersonatedUser?: AuthProfile;
    impersonatedUserId?: string;
    role?: DDDEntity<RolePayload>;
    canImpersonate: boolean;
    emailRestorePasswordWasSend: boolean;
    passwordRestored: boolean;
    passwordRestorationTimeHasPassed: boolean;
}
const INITIAL_AUTH_STATE: AuthStateModel = {
    accessToken: null,
    hostAccessToken: null,
    canImpersonate: false,
    emailRestorePasswordWasSend: false,
    passwordRestored: false,
    passwordRestorationTimeHasPassed: false,
    userId: null,
    profile: null,
};

// копия этого стора хранится в localStorage с помошью NgxsStoragePluginModule
@State<AuthStateModel>({
    name: 'auth',
    defaults: INITIAL_AUTH_STATE,
})
@Injectable()
export class AuthState {
    constructor(
        private readonly router: Router,
        private readonly ngZone: NgZone,
        private readonly authQueryService: AuthQueryService,
        private readonly messageBarService: MessageBarService
    ) { }

    @Selector()
    public static isAuth(state: AuthStateModel): boolean {
        return !!state.accessToken;
    }

    @Selector()
    public static role(state: AuthStateModel): DDDEntity<RolePayload> {
        return state.role;
    }

    @Selector()
    public static roleIdent(state: AuthStateModel): string {
        return state.role?.payload?.ident;
    }

    @Selector()
    public static userName(state: AuthStateModel): string {
        const profile = state.profile;
        return HelperUtil.isValidPrimitives(profile.firstName, profile.lastName)
            ? `${profile.firstName} ${profile.lastName}`.trim()
            : profile.username;
    }

    @Selector()
    public static isNotAuth(state: AuthStateModel): boolean {
        return !state.accessToken;
    }

    @Selector()
    public static accessToken(state: AuthStateModel): string {
        return state.accessToken;
    }

    @Selector()
    public static email(state: AuthStateModel): string {
        return state.profile?.email;
    }

    @Selector()
    public static profile(state: AuthStateModel): AuthProfile {
        return state.profile;
    }

    @Selector()
    public static canImpersonate(state: AuthStateModel): boolean {
        return state.canImpersonate;
    }

    @Selector()
    public static getImpersonatedUser(state: AuthStateModel): AuthProfile {
        return state.impersonatedUser;
    }

    @Selector()
    public static emailRestorePasswordWasSend(state: AuthStateModel): boolean {
        return state.emailRestorePasswordWasSend;
    }

    @Selector()
    public static passwordRestorationTimeHasPassed(state: AuthStateModel): boolean {
        return state.passwordRestorationTimeHasPassed;
    }

    @Selector()
    public static passwordRestored(state: AuthStateModel): boolean {
        return state.passwordRestored;
    }

    @Selector()
    public static userId(state: AuthStateModel): string {
        return state.hostAccessToken
            ? state.impersonatedUserId
            : state.userId;
    }

    @Selector()
    public static hostUserId(state: AuthStateModel): string {
        return state.userId;
    }

    @Selector()
    public static currentUsername(state: AuthStateModel): string {
        return state.hostAccessToken ? state.impersonatedUser?.email : state.profile?.email;
    }

    @Action(AuthLogoutAction)
    public authLogout({ setState }: StateContext<AuthStateModel>) {
        setState(INITIAL_AUTH_STATE);
        window.location.reload();
    }

    @Action(AuthLoginAction)
    public loginCompleteAction(
        { patchState, dispatch }: StateContext<AuthStateModel>,
        { payload }: AuthLoginAction,
    ) {
        return this.authQueryService.login(payload).pipe(
            tap((d) => {
                // NgxsStoragePluginModule хранит дубликат в localStorage стора auth
                patchState({
                    ...INITIAL_AUTH_STATE,
                    accessToken: d.access_token,
                });
                dispatch(new AuthGetProfileAction());
                const navigation = APP_URL_MAP.home.url ? ['/', APP_URL_MAP.home.url] : ['/'];
                this.ngZone.run(() => this.router.navigate(navigation));
            }),
            catchError((err) => {
                if (
                    (err.error.statusCode === 400 &&
                        err.error.message.indexOf('email must be an email') !== -1) ||
                    err.error.statusCode === 401
                ) {
                    this.messageBarService.warn('Неверный e-mail или пароль');
                }
                throw err;
            }),
        );
    }

    /** получение профиля логина */
    @Action(AuthGetProfileAction)
    getProfileAction({ getState, patchState, dispatch }: StateContext<AuthStateModel>) {
        const state = getState();
        if (!state.accessToken) {
            return;
        }
        return this.authQueryService.profile().pipe(
            tap((response) => {
                // если юзер подменен
                if (state.hostAccessToken) {
                    patchState({
                        impersonatedUserId: response.clientId,
                        impersonatedUser: response.profile,
                    });
                } else {
                    patchState({
                        userId: response.clientId,
                        profile: response.profile,
                    });
                }

                dispatch(AuthGetUserRoleAction);
                dispatch(AuthCheckImpersonationAction);
            }),
        );
    }

    @Action(SetImpersonationAction)
    setImpersonationAction(
        { patchState, getState, dispatch }: StateContext<AuthStateModel>,
        { impersonatedUser }: SetImpersonationAction,
    ) {
        const state = getState();
        const hostAccessToken = state.accessToken;
        return this.authQueryService.impersonatedUserToken(impersonatedUser.payload.email).pipe(
            tap(({ accessToken }) => {
                patchState({
                    impersonatedUser: impersonatedUser.payload,
                    hostAccessToken,
                    accessToken,
                });
                dispatch(AuthGetProfileAction);
                this.router.navigate(['/']);
            }),
        );
    }

    @Action(ClearImpersonationAction)
    clearImpersonationAction({ patchState, getState, dispatch }: StateContext<AuthStateModel>) {
        const state = getState();
        const hostAccessToken = state.hostAccessToken;

        patchState({
            impersonatedUser: null,
            impersonatedUserId: null,
            hostAccessToken: null,
            accessToken: hostAccessToken,
        });
        dispatch(AuthGetProfileAction);
    }

    @Action(AuthSendEmailRestorePasswordAction)
    sendEmailRestorePassword({ patchState }: StateContext<AuthStateModel>, { email }: AuthSendEmailRestorePasswordAction) {
        return this.authQueryService.sendEmailRestorePassword(email).pipe(
            tap(() => {
                patchState({
                    emailRestorePasswordWasSend: true,
                });
            })
        );
    }

    @Action(ClearAuthRestorePasswordAction)
    clearAuthRestorePassword({ patchState }: StateContext<AuthStateModel>) {
        patchState({
            emailRestorePasswordWasSend: false,
            passwordRestored: false,
            passwordRestorationTimeHasPassed: false,
        });
    }

    @Action(AuthRestorePasswordAction)
    restorePassword({ patchState }: StateContext<AuthStateModel>, { payload }: AuthRestorePasswordAction) {
        return this.authQueryService.restorePassword(payload).pipe(
            tap((d) => {
                if (d.status === 'WRONG_CODE') {
                    patchState({
                        passwordRestorationTimeHasPassed: true,
                    });
                } else {
                    patchState({
                        passwordRestored: true,
                    });
                }
            }),
        );
    }

    @Action(AuthGetUserRoleAction, { cancelUncompleted: true })
    getUserRole(
        { patchState, getState }: StateContext<AuthStateModel>
    ): Observable<DDDEntity<RolePayload>> {
        const state = getState();

        const userId = state.hostAccessToken
            ? state.impersonatedUserId
            : state.userId;

        return this.authQueryService.getRole(userId).pipe(
            tap(role => patchState({ role }))
        );
    }

    @Action(AuthCheckImpersonationAction, { cancelUncompleted: true })
    checkImpersonation(
        { patchState, getState }: StateContext<AuthStateModel>
    ): Observable<boolean> {
        const state = getState();

        if (state.hostAccessToken) {
            patchState({ canImpersonate: false });
        } else {
            return this.authQueryService.checkImpersonation(state.userId).pipe(
                tap(canImpersonate => patchState({ canImpersonate })),
                catchError(error => {
                    patchState({ canImpersonate: false });
                    return throwError(error);
                })
            );
        }
    }
}

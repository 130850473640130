import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private historyCount: number = 0;

    constructor(
        private readonly router: Router,
        private readonly location: Location
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.historyCount += 1;
        });
    }

    navigateBack(defaultUrl: string = '/'): void {
        this.historyCount -= 1;

        if (this.historyCount > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl(defaultUrl);
        }
    }
}

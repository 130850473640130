export class ClientPayloadModel {
    email: string = '';
    username: string = '';
    firstName: string = '';
    lastName: string = '';
    middleName: string = '';
    phone: string = '';
    startDate?: Date;
    endDate?: Date;
    orgName: string = '';
    role: string = '';
    vehicleCount: number = 0;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackBtnDirective } from './back-btn.directive';



@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        BackBtnDirective,
    ],
    exports: [
        BackBtnDirective,
    ],
    providers: [],
})
export class BackBtnModule { }

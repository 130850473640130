/**
 * @example
 * {
 *   fieldName: 'contractNumber',
 *   fieldType: 'string',
 *   filterType: 'like',
 *   value: value || '', // input value here
 * }
 *
 */
export const insuranceContractNumberLike = (value) => ({
    value: value ?? '',
    fieldName: 'contractNumber',
    fieldType: 'string',
    filterType: 'like',
});

/**
 * @example
 * {
 *   fieldName: 'status',
 *   fieldType: 'string',
 *   filterType: 'in',
 *   value: value || '', // input value here
 * }
 *
 */
export const insuranceStatusIn = (value) => ({
    value: value,
    fieldName: 'status',
    fieldType: 'string',
    filterType: 'in',
});

/**
 * @example
 * {
 *   fieldName: 'type',
 *   fieldType: 'string',
 *   filterType: 'equals',
 *   value, // input value here
 * }
 *
 */
export const typeInsuranceEquals = (value) => ({
    value,
    fieldName: 'type',
    fieldType: 'string',
    filterType: 'equals',
});

/**
 * @example
 * {
 *   fieldName: 'email',
 *   fieldType: 'string',
 *   filterType: 'equals',
 *   value, // input value here
 * }
 *
 */
export const emailEquals = (value) => ({
    value,
    fieldName: 'email',
    fieldType: 'string',
    filterType: 'equals',
});

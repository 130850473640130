import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    CarsChangeViewAction,
    CarsColumnsAction,
    CarsFiltersAction,
    CarsGetPageAction,
    CarsPaginatorEventAction,
    CarsSearchAction,
    CarsSortAction,
    CarsViewCarsAction,
    CarsViewTableAction,
    CarsLoadVehicleStatusCounts,
    CarsGetCarEvents,
    SetMileage,
    CarsGetSimIdByVinCar,
    CarsGetVehicles,
    CarsGetVehicleMainInfo
} from './cars.state.actions';
import {TableFilterItem} from '@shared/fleet-table/interfaces/data-query-service.interface';
import {FLEET_TABLE_INITIAL, FleetTable} from '@shared/fleet-table/interfaces/fleet-table.interface';
import {CarsQueryService} from './cars-query.service';
import {Sort} from '@angular/material/sort';
import {CarsViews} from './interfaces/cars-view.iterface';
import {FleetVehicle} from './interfaces/fleet-vehicle.interface';
import {RefItem} from '@shared/interfaces/ref-item.interface';
import {HttpClient} from '@angular/common/http';
import {InterfaceIndicator} from '@app/pages/cars/store/interfaces/indicator.interface';
import {InterfaceCurCarEvents} from '@app/pages/cars/store/interfaces/cur-car-events.interface';
import {HelperUtil} from '@shared/helper.util';
import {FleetVehicleFullInfo} from '@app/pages/cars/store/interfaces/fleet-vehicle-full-info.interface';
import {catchError, map, tap, take, switchMap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {MonitoringVehicleStatusesResponse} from '@monitoringModule/state/interface/monitoring-vehicle-statuses.response';
import {MonitoringStatisticsQueryService} from '@monitoringModule/state/services/monitoring-statistics-query.service';
import {DDDLayoutQueryService} from '@app/core/ddd-layout/services/ddd-layout-query.service';
import {Vehicle} from '@app/pages/cars/store/interfaces/vehicle.interface';
import {VehicleMainInfo} from '@app/pages/cars/store/interfaces/vehicle-main-info.interface';
import {EMPTY} from 'rxjs';
import {
    DDDEntitySelectAggregator,
    DDDEntitySelectRequestDto,
    DDDEntitySelectSorting
} from '@app/core/ddd-layout/dto/ddd-entity-select.request.dto';
import {emailEquals, registrationNumberLike} from '@app/pages/cars/store/filters';
import {MonitoringQueryService} from '@monitoringModule/state/services/monitoring-query.service';
import { ERole } from '@shared/app-roles.const';
import { CarsBmoQueryService } from '@shared/cars/services/cars-bmo-query.service';
import { DDDEntity } from '@app/core/ddd-layout/interfaces/ddd-entity.interface';
import { ClientEntity } from '@shared/client/interfaces/client-entity.interface';

const INITIAL_CARS_STATE = {
    ...FLEET_TABLE_INITIAL,
    cars: [],
    search: '',
    searchResultText: 'Без результатов',
    view: 'table' as CarsViews,
    statusRef: [],
    transportPark: {},
    carEvents: [],
    indicator: [],
    curCarEvents: new InterfaceCurCarEvents(),
    carSimId: null,
    vehicles: [],
    vehicleMainInfo: null,
    amount: 0,
    loader: false,
    sorting: new DDDEntitySelectSorting(),
    currentUsername: '',
};

export interface CarsStateModel extends FleetTable {
    cars: FleetVehicle[],
    search: string;
    searchResultText: string;
    view: CarsViews;
    statusRef: RefItem[];
    carEvents: InterfaceCurCarEvents[];
    indicator: InterfaceIndicator[];
    curCarEvents: InterfaceCurCarEvents;
    carSimId: string;
    transportPark: Record<string, string>;
    vehicleStatusCounts?: MonitoringVehicleStatusesResponse;
    vehicles: Vehicle[];
    vehicleMainInfo: VehicleMainInfo;
    amount: number;
    loader: boolean;
    sorting: DDDEntitySelectSorting;
    currentUsername: string;
}

@State<CarsStateModel>({
    name: 'cars',
    defaults: INITIAL_CARS_STATE
})
@Injectable()
export class CarsState {
    constructor(private readonly carsQueryService: CarsQueryService,
        private readonly dddQueryService: DDDLayoutQueryService,
        private readonly monitoringQueryService: MonitoringQueryService,
        private readonly monitoringStatisticsQuery: MonitoringStatisticsQueryService,
        private readonly httpClient: HttpClient,
        private readonly carsBmoQueryService: CarsBmoQueryService) {
    }

    @Selector()
    public static view(state: CarsStateModel): CarsViews {
        return state.view;
    }

    @Selector()
    public static searchResultText(state: CarsStateModel): string {
        return state.searchResultText;
    }

    @Selector()
    static getCurCarEvents(state: CarsStateModel) {
        return state.curCarEvents;
    }

    @Selector()
    static getCarEvents(state: CarsStateModel) {
        return state.carEvents;
    }

    @Selector()
    static getVehicleStatusCounts(state: CarsStateModel): MonitoringVehicleStatusesResponse {
        return state.vehicleStatusCounts;
    }

    @Selector()
    static getTransportPark(state: CarsStateModel): Record<string, string> {
        return state.transportPark;
    }

    @Selector()
    static getCarsFullInfo(state: CarsStateModel): FleetVehicleFullInfo[] {
        return state.cars.map((car) => {
            const transportPark = state.transportPark[car.vin];
            return { ...car, transportPark };
        });
    }

    @Selector()
    static getCarSimId(state: CarsStateModel): string {
        return state.carSimId;
    };

    @Selector()
    static getVehicles(state: CarsStateModel) {
        return state.vehicles;
    };

    @Selector()
    static getVehicleMainInfo(state: CarsStateModel) {
        return state.vehicleMainInfo;
    };

    @Selector()
    public static cars(state: CarsStateModel): FleetVehicle[] {
        return state.cars;
    }

    @Selector()
    public static total(state: CarsStateModel): number {
        return state.total;
    }

    @Selector()
    public static amount(state: CarsStateModel): number {
        return state.amount;
    }

    @Selector()
    public static loader(state: CarsStateModel): boolean {
        return state.loader;
    }

    @Selector()
    public static page(state: CarsStateModel): number {
        return state.page;
    };

    @Selector()
    public static onPage(state: CarsStateModel): number {
        return state.onPage;
    };

    @Selector()
    public static search(state: CarsStateModel): string {
        return state.search;
    };

    @Selector()
    public static filters(state: CarsStateModel): TableFilterItem[] {
        return state.filters;
    };

    @Selector()
    public static columns(state: CarsStateModel): string[] {
        return state.columns;
    };

    @Selector()
    public static sort(state: CarsStateModel): Sort {
        return state.sort;
    };

    @Selector()
    public static statusRef(state: CarsStateModel): RefItem[] {
        return state.statusRef;
    };


    @Action(CarsChangeViewAction)
    changeViewAction({ patchState }: StateContext<CarsStateModel>, { payload }: CarsChangeViewAction) {
        patchState({ view: payload });
    }

    @Action(CarsViewTableAction)
    viewTableAction({ patchState }: StateContext<CarsStateModel>) {
        patchState({ view: 'table' });
    }

    @Action(CarsViewCarsAction)
    viewCarsAction({ patchState }: StateContext<CarsStateModel>) {
        patchState({ view: 'cards' });
    }

    @Action(CarsLoadVehicleStatusCounts, { cancelUncompleted: true })
    loadVehicleStatusCounts({ patchState }: StateContext<CarsStateModel>): Observable<MonitoringVehicleStatusesResponse> {
        return this.monitoringStatisticsQuery.getVehicleStatuses()
            .pipe(tap(res => patchState({ vehicleStatusCounts: res })));
    }

    @Action(CarsGetCarEvents)
    carEvents({ patchState }: StateContext<CarsStateModel>, { payLoad }: CarsGetCarEvents) {
        const callUrl = '/mta/carv2/carEvents';
        return this.httpClient.post(callUrl, { sim_id: payLoad.sim_id })
            .pipe(
                map((response: any) => {
                    patchState(
                        {
                            carEvents: response,
                            curCarEvents: response?.events[0] ? response.events[0] : null,
                            indicator: response.indicator,
                        }
                    );
                }),
                catchError(() => {
                    patchState({
                        carEvents: INITIAL_CARS_STATE.carEvents,
                        curCarEvents: INITIAL_CARS_STATE.curCarEvents,
                        indicator: INITIAL_CARS_STATE.indicator,
                    });
                    return EMPTY;
                })
            );
    }

    @Action(SetMileage)
    setMileage({ patchState }: StateContext<CarsStateModel>, { payLoad }: SetMileage) {
        const callUrl = '/mta/manage/set-mileage';
        patchState({ loader: true });
        return this.httpClient.post(callUrl, {
            deviceId: payLoad.deviceId,
            mileage: payLoad.mileage
        }).pipe(take(1)).subscribe(() => {
            patchState({ loader: false });
        }, () => {
            patchState({ loader: false });
        });
    }

    @Action(CarsSearchAction)
    searchChangeAction({ patchState, getState, dispatch }: StateContext<CarsStateModel>, { payload }: CarsSearchAction) {
        const { currentUsername } = getState();

        patchState({ search: payload });

        dispatch(new CarsGetVehicles(0, currentUsername));
    }


    @Action(CarsGetPageAction)
    async getPageAction({ patchState, getState }: StateContext<CarsStateModel>, { payload }: CarsGetPageAction) {
        const {
            onPage,
            search,
            filters,
            sort
        } = getState();
        const carsDto = await this.carsQueryService.get(payload, onPage, search, filters, sort);
        patchState({ page: carsDto.page, cars: carsDto.rows, total: carsDto.rowCount || 0 });
        return;
    }

    @Action(CarsGetVehicles, { cancelUncompleted: true })
    getVehicles({ patchState, getState }: StateContext<CarsStateModel>, { page, currentUsername }: CarsGetVehicles) {
        patchState({ currentUsername });

        const { onPage, sorting, search } = getState();

        const carAggregator: DDDEntitySelectAggregator = {
            type: 'car',
            aggregators: [
                {
                    type: 'car_props'
                },
                {
                    type: 'city'
                },
                {
                    type: 'client',
                    aggregators: [
                        {
                            type: 'role',
                        },
                    ],
                },
            ],
            sorting,
            filters: {
                ids: [],
                fields: [registrationNumberLike(search)],
            },
        };

        const entitiesParams: DDDEntitySelectRequestDto = {
            aggregators: [
                carAggregator,
                {
                    type: 'park',
                    aggregators: [carAggregator],
                },
            ],
            filters: {
                ids: [],
                fields: [emailEquals(currentUsername)]
            }
        };

        return this.dddQueryService.getEntities('client', entitiesParams).pipe(
            map(response => {
                const clientVehicles: DDDEntity[] = response.data.client[0]?.car ?? [];
                const parkVehicles: DDDEntity[] = response.data.client[0]?.park?.[0]?.car ?? [];

                return clientVehicles.concat(parkVehicles);
            }),
            switchMap(vehicles => {
                const deviceIds = vehicles.map(vehicle => vehicle.payload?.deviceId ?? vehicle.payload?.sim_id).filter(i => !!i);

                return this.carsBmoQueryService.getCarsStatusInfo({ deviceIds }).pipe(
                    map(statusResult => {
                        return vehicles.map(vehicle => {
                            const deviceId = vehicle.payload?.deviceId || vehicle.payload?.sim_id;
                            const statusRow = statusResult.payload.result.devices.find(device => device.deviceId === deviceId);
                            const rentalPeriod = vehicle.payload?.deals?.[0]?.rentalPeriod.split(',')[0];
                            const mileage = vehicle.payload?.deals?.[0]?.mileage.split(',')[0];

                            return {
                                sim_id: deviceId,
                                rentalPeriod,
                                mileage,
                                id: vehicle.id,
                                carBrand: vehicle.payload?.carBrand,
                                model: vehicle.payload?.model,
                                license: vehicle.payload?.license || vehicle.payload?.registrationNumber,
                                vin: vehicle.payload?.vin,
                                rentStart: vehicle.payload?.deals?.[0]?.rentStart,
                                planRentEnd: vehicle.payload?.deals?.[0]?.planRentEnd,
                                contractMileage: vehicle.payload?.deals?.[0]?.contractMileage,
                                city: vehicle.city?.[0]?.payload?.name,
                                transportPark: vehicle.payload?.dialerName,
                                ignition: statusRow?.ignition ?? null,
                                triggerTime: statusRow?.triggerTime ?? null,
                                drivers: vehicle.client?.filter((client: ClientEntity) => client.role?.[0]?.payload?.ident === ERole.Driver)?.length ?? 0,
                                rgb_code: null,
                            }
                        });

                    })
                );
            }),
            tap(vehicles => patchState({
                vehicles,
                currentUsername,
                amount: vehicles.length,
            })),
            catchError(() => {
                patchState({
                    vehicles: [],
                });
                return EMPTY;
            })
        );
    }

    @Action(CarsGetVehicleMainInfo)
    getVehicleMainInfo({ patchState }: StateContext<CarsStateModel>, { payload }: CarsGetVehicleMainInfo) {
        return this.dddQueryService.getEntity('car', payload, ['car_props']).pipe(
            map((item) => {
                const vehicleMainInfo: VehicleMainInfo = {
                    id: item.id,
                    sim_id: item.payload?.simId || item.payload?.deviceId,
                    carBrand: item.payload?.carBrand,
                    model: item.payload?.model,
                    license: item.payload?.license || item.payload?.registrationNumber,
                    vin: item.payload?.vin,
                    sts_number: item.payload?.stsNumber,
                    rgb_code: item.car_props[0]?.payload?.external?.hexColor,
                    color_i18n: {
                        ru: item.car_props[0]?.payload?.exteriorColor
                    },
                    interior_i18n: {
                        ru: item.car_props[0]?.payload.external.interiorColorCode
                    }
                };

                patchState({
                    vehicleMainInfo
                });
            })
        );
    }

    @Action(CarsGetSimIdByVinCar)
    async getSimIdByVinCar({ patchState }: StateContext<CarsStateModel>, { payLoad }: CarsGetSimIdByVinCar) {
        const { rows } = await this.carsQueryService.get(0, 100, payLoad);
        const carSimId = rows[0].sim_id;
        patchState({ carSimId });
    }

    @Action(CarsPaginatorEventAction)
    setOnPageAction({ getState, patchState, dispatch }: StateContext<CarsStateModel>, { payload }: CarsPaginatorEventAction) {
        const { page, onPage, currentUsername } = getState();
        if (onPage !== payload.pageSize || payload.pageIndex !== page) {
            patchState({ onPage: payload.pageSize, page: payload.pageIndex });
            dispatch(new CarsGetVehicles(onPage !== payload.pageSize ? 0 : payload.pageIndex, currentUsername));
        }
    }

    @Action(CarsSortAction)
    sortAction({ patchState, getState, dispatch }: StateContext<CarsStateModel>, { payload }: CarsSortAction) {
        const { currentUsername } = getState();

        patchState({
            sorting: {
                fieldName: payload.active,
                sortDirection: payload.direction,
            }
        });

        dispatch(new CarsGetVehicles(0, currentUsername));
    }

    @Action(CarsFiltersAction)
    filtersAction({ dispatch, patchState }: StateContext<CarsStateModel>, { payload }: CarsFiltersAction) {
        patchState({ filters: payload });
        dispatch(new CarsGetPageAction(0));
    }

    @Action(CarsColumnsAction)
    columnsAction({ patchState }: StateContext<CarsStateModel>, { payload }: CarsColumnsAction) {
        patchState({ columns: payload });
    }


}

import {TableFilterItem} from '@shared/fleet-table/interfaces/data-query-service.interface';
import {PageEvent} from '@angular/material/paginator/paginator';
import {Sort} from '@angular/material/sort';
import {CarsViews} from './interfaces/cars-view.iterface';
import {InterfaceGetCarEvents} from '@app/pages/cars/store/interfaces/get-car-events.interface';
import {InterfaceSetMileage} from '@app/pages/cars/store/interfaces/set-mileage.interface';

export class CarsSearchAction {
    public static readonly type = '[Cars] search cars';
    constructor(public payload: string) {}
}

export class CarsGetPageAction {
    public static readonly type = '[Cars] get page';
    constructor(public payload: number) {}
}

export class CarsGetVehicles {
    public static readonly type = '[Cars] get vehicles';
    constructor(public page: number,
                public currentUsername?: string
    ) {}
}

export class CarsGetVehicleMainInfo {
    public static readonly type = '[Cars] get vehicle main info';
    constructor(public payload: string) {}
}

export class CarsGetStatusRefAction {
    public static readonly type = '[Cars] get statuses';

}

export class CarsPaginatorEventAction {
    public static readonly type = '[Cars] event paginator';
    constructor(public payload: PageEvent) {}
}

export class CarsSortAction {
    public static readonly type = '[Cars] sort data';
    constructor(public payload: Sort) {}
}

export class CarsFiltersAction {
    public static readonly type = '[Cars] set filters';
    constructor(public payload: TableFilterItem[]) {}
}

export class CarsColumnsAction {
    public static readonly type = '[Cars] set filters';
    constructor(public payload: string[]) {}
}

export class CarsChangeViewAction {
    public static readonly type = '[Cars] change view';

    constructor(public payload: CarsViews) {
    }
}

export class CarsViewTableAction {
    public static readonly type = '[Cars] set table view';
}

export class CarsViewCarsAction {
    public static readonly type = '[Cars] set cards view';
}

export class CarsLoadVehicleStatusCounts {
    public static type = '[Cars] Load Vehicle Status Counts';
}

export class CarsGetCarEvents {
    static readonly type = '[Cars] Get Car Events';

    constructor(public payLoad: InterfaceGetCarEvents) {
    }
}

export class SetMileage {
    static readonly type = '[Cars] Set Mileage';

    constructor(public payLoad: InterfaceSetMileage) {
    }
}

export class CarsGetSimIdByVinCar {
    static readonly type = '[Cars] Get Sim-Id';

    constructor(public payLoad: string) {
    }
}

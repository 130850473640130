import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { APP_URL_MAP } from './core/app-url-map.';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./core/app-core.module').then(m => m.AppCoreModule),
        canActivate: [ AuthGuard ],
        data: { preload: false },
    },
    {
        path: APP_URL_MAP.login.url,
        loadChildren: () =>
            import('./core/auth/auth.module').then(m => m.AuthModule),
    },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'top',
    }) ],
    exports: [ RouterModule ],
})
export class AppRoutingModule {
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing.module';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@environments/environment';
import { AppState } from './core/store/app.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { StatesChanges } from './shared/utils/stateChanges.const';
import { AuthState } from './core/auth/store/auth.state';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { LayoutState } from './core/layout/store/layout.state';
import { AppHttpInterceptor } from './app-http.interceptor';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { myRxStompConfig } from './pages/monitoring/utils/stomp/stomp.config';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthQueryService } from './core/auth/services/auth-query.service';
import { MessageBarModule } from './shared/message-bar/message-bar.module';
import { MessageBarService } from './shared/message-bar/message-bar.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MonitoringApiModule } from './shared/monitoring-api/monitoring-api.module';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { ClientQueryService } from './core/auth/store/services/client-query.service';
import { DDDLayoutQueryService } from './core/ddd-layout/services/ddd-layout-query.service';
import { BackBtnModule } from './core/back-btn/back-btn.module';
import { NavigationService } from './core/services/navigation.service';
registerLocaleData(localeRu);

@NgModule({
    declarations: [ AppComponent ],
    imports: [
        MonitoringApiModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        RouteHandlerModule,
        MessageBarModule,
        MatSnackBarModule,
        BackBtnModule,
        NgxsModule.forRoot([ LayoutState, AppState, AuthState ], {
            developmentMode: !environment.production,
        }),
        NgxsLoggerPluginModule.forRoot({
            collapsed: false,
            disabled: environment.production
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsStoragePluginModule.forRoot({
            key: [ 'auth' ],
            migrations: StatesChanges.STATES_CHANGES_STORE,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        {
            provide: InjectableRxStompConfig,
            useValue: myRxStompConfig,
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
            deps: [ InjectableRxStompConfig ],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        AuthQueryService,
        MessageBarService,
        ClientQueryService,
        DDDLayoutQueryService,
        { provide: LOCALE_ID, useValue: 'ru-RU' },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'RUB',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => null,
            deps: [NavigationService],
            multi: true,
        },
    ],

    bootstrap: [ AppComponent ],
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { environment } from '@environments/environment';
import { AuthLogoutAction } from './core/auth/store/auth.actions';
import { AuthState } from './core/auth/store/auth.state';
import { MessageBarService } from './shared/message-bar/message-bar.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        private messageBarService: MessageBarService
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const accessToken = this.store.selectSnapshot(AuthState.accessToken);
        const headers = request.headers.append('x-project', 'fleet')
            .append('Authorization', 'Bearer ' + this.store.selectSnapshot(AuthState.accessToken));

        const externalUrl = new RegExp('^https://');
        const url = externalUrl.test(request.url) ? request.url : environment.apiUrl + request.url;
        const customReq = request.clone({
            url,
            headers
        });

        return next.handle(customReq).pipe(
            map(data => {

                return data;
            }),
            catchError(error => {
                const isCustomerRequest = (new RegExp(`^${environment.apiUrl}${environment.urlCustomerService}`)).test(url);

                if (error.status === 401 && accessToken !== null && !isCustomerRequest) {
                    this.messageBarService.warn('Сессия истекла');
                    this.store.dispatch(new AuthLogoutAction());
                } else if (error.status === 504) {
                    this.messageBarService.warn('Сервис не отвечает. Повторите попытку.');

                } else if (error.status === 500) {
                    this.messageBarService.warn('Внутренняя ошибка сервера.');
                } else if (error.status === 400 && error.error?.message) {
                    this.messageBarService.warn(error.error?.message);
                }
                throw error;
            })
        );
    }
}

import { Injectable } from '@angular/core';
import { AuthLoginForm, AuthProfile, AuthResponse, ResponseEmailCode } from '../auth.interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseStatus } from '@shared/models/responses';
import { PasswordRestoration } from '@app/core/auth/store/models/password-restoration';
import { environment } from '@environments/environment';
import { RolePayload } from '../../ddd-layout/interfaces/role-payload.interface';
import { DDDEntity } from '../../ddd-layout/interfaces/ddd-entity.interface';
import { DDDLayoutQueryService } from '../../ddd-layout/services/ddd-layout-query.service';
import { map } from 'rxjs/operators';
import { AuthProfileResponseDto } from '../dto/auth-profile.response.dto';

@Injectable()
export class AuthQueryService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly dddQueryService: DDDLayoutQueryService
    ) { }

    login(payload: AuthLoginForm): Observable<AuthResponse> {
        const url = '/bmo/auth/login';
        return this.httpClient.post<AuthResponse>(url, {
            email: payload.login,
            password: payload.password,
        });
    }

    profile(): Observable<AuthProfileResponseDto> {
        const url = '/bmo/auth/profile';
        return this.httpClient.get<AuthProfileResponseDto>(url);
    }

    /**
     * @deprecated
     * @param email 
     * @returns 
     */
    sendEmailCode(email: string): Observable<ResponseEmailCode> {
        return this.httpClient.get<ResponseEmailCode>(
            `${environment.urlCustomerService}/fleet/auth/sendEmailCode?email=${email}`,
        );
    }

    impersonatedUserToken(email: string): Observable<{ accessToken: string }> {
        const url = '/bmo/auth/impersonate';

        return this.httpClient.post<{ accessToken: string }>(url, { email });
    }

    sendEmailRestorePassword(email: string): Observable<ResponseStatus> {
        const url = '/bmo/auth/passwordRestore';

        return this.httpClient.get<ResponseStatus>(url, { params: { email } });
    }

    restorePassword(d: PasswordRestoration): Observable<ResponseStatus> {
        const url = '/bmo/auth/passwordRestore';

        return this.httpClient.post<ResponseStatus>(url, d);
    }

    getRole(userId: string): Observable<DDDEntity<RolePayload>> {
        return this.dddQueryService.getEntity('client', userId, ['role']).pipe(
            map(response => response.role?.[0])
        )
    }

    checkImpersonation(userId: string): Observable<boolean> {
        return this.dddQueryService.getEntities('client', {
            aggregators: [
                {
                    type: 'impersonation',
                    aggregators: [
                        {
                            type: 'client',
                            pagination: {
                                onPage: 1,
                                page: 0,
                            },
                        },
                    ],
                },
            ],
            filters: { ids: [userId] },
        }).pipe(
            map(response => response.data.client?.[0]?.impersonation?.[0]?.clientPagination?.total),
            map(impersonationClients => impersonationClients > 0)
        );
    }
}

import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppUpdateAction} from '../store/app.state.actions';

@Component({
    template:
            `
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="title-text">Доступно обновление.</span>
            <div fxFlex="auto"></div>
            <button mat-stroked-button class="update-btn" color="primary" (click)="update()">Обновить</button>
            <div fxFlex="2rem"></div>
            <button mat-icon-button class="close-btn" (click)="close()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>

    `
})

export class UpdaterComponent implements OnInit {

    constructor(
        private _snackBar: MatSnackBar,
        private store: Store
    ) {
    }

    ngOnInit() {
    }

    update() {
        this.store.dispatch(new AppUpdateAction());
    }

    close() {
        this._snackBar._openedSnackBarRef.dismiss();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DDDNewEntityResponseDto } from '../dto/ddd-new-entity.response.dto';
import { DDDEntityResponseDto } from '../dto/ddd-entity.response.dto';
import { DDDUiAggregateRole } from '../interfaces/ddd-ui-aggregate-role.interface';
import { map, tap } from 'rxjs/operators';
import { DDDRoleAggregateUI } from '../interfaces/ddd-role-aggregate-ui.interface';
import { UiType } from '../enum/ui-type.enum';
import { DDDClientAggregateRole } from '../interfaces/ddd-client-aggregate-role.interface';
import { DDDEntity } from '../interfaces/ddd-entity.interface';
import { DDDEntitySelectAggregator, DDDEntitySelectRequestDto } from '../dto/ddd-entity-select.request.dto';
import { DDDEntityLinkResponseDto } from '../dto/ddd-link.response.dto';
import { MessageBarService } from '@shared/message-bar/message-bar.service';
import { DDDEntityUpdateStatusResponseDto } from '../dto/ddd-entity-update-status.response.dto';
import { SelectEntityParams } from '../models/select-entity-params.model';
import { DDDBaseResponseDto } from '../dto/ddd-base-response.dto';
import { DDDUpdateEntityResponseDto } from '../dto/ddd-update-entity.response.dto';
import { DDDDeleteEntityResponseDto } from '../dto/ddd-delete-entity.response.dto';
import { DDDUnlinkResponseDto } from '../dto/ddd-unlink.response.dto';

@Injectable({
    providedIn: 'root'
})
export class DDDLayoutQueryService {
    private readonly urlPrefix = '/bmo/ddd';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly messageBarService: MessageBarService
    ) { }

    addEntity<P>(type: string, payload: P, linkTo: string[] = []): Observable<DDDNewEntityResponseDto> {
        const url = `${this.urlPrefix}/entity/insert/${type}`;

        return this.httpClient.post<DDDNewEntityResponseDto>(url, {
            payload,
            linkTo,
        }).pipe(
            tap(res => this.checkResponse(res))
        );
    }

    getRoleAggregatedWithUi(role?: string): Observable<DDDRoleAggregateUI[]> {
        const url = `${this.urlPrefix}/entity/select/role`;

        const body = {
            aggregators: [
                {
                    type: 'ui',
                },
            ],
            filters: {
                fields: []
            },
        };

        if (role) {
            body.filters.fields.push({
                fieldType: 'string',
                fieldName: 'ident',
                filterType: 'equals',
                value: role,
            });
        }

        return this.httpClient.post<DDDEntityResponseDto<{ role: DDDRoleAggregateUI[] }>>(url, body)
            .pipe(
                tap(res => this.checkResponse(res)),
                map(result => result.data.role)
            );
    }

    getUiListAggregatedWithRole(type?: UiType): Observable<DDDUiAggregateRole[]> {
        const url = `${this.urlPrefix}/entity/select/ui`;

        const body = {
            aggregators: [
                {
                    type: 'role',
                },
            ],
            filters: {
                fields: [],
            },
        }

        if (type) {
            body.filters.fields.push({
                fieldType: 'string',
                fieldName: 'type',
                filterType: 'equals',
                value: type,
            });
        }

        return this.httpClient.post<DDDEntityResponseDto<{ ui: DDDUiAggregateRole[] }>>(url, body).pipe(
            tap(res => this.checkResponse(res)),
            map(result => result.data.ui)
        );
    }

    link(entityId: string, linkTo: string[]): Observable<DDDEntityLinkResponseDto> {
        const url = `${this.urlPrefix}/link/${entityId}`;

        return this.httpClient.post<DDDEntityLinkResponseDto>(url, { linkTo })
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    unlink(entityId: string, unlink: string[]): Observable<DDDUnlinkResponseDto> {
        const url = `${this.urlPrefix}/unlink/${entityId}`;

        return this.httpClient.post<DDDUnlinkResponseDto>(url, { unlink })
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    updateEntity<P>(id: string, payload: P): Observable<DDDUpdateEntityResponseDto> {
        const url = `${this.urlPrefix}/entity/${id}`;

        return this.httpClient.put<DDDUpdateEntityResponseDto>(url, { payload })
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    getClientAggregatedWithRole(username: string): Observable<DDDClientAggregateRole> {
        const url = `${this.urlPrefix}/entity/select/client`;

        const body = {
            aggregators: [
                {
                    type: 'role',
                },
            ],
            filters: {
                fields: [
                    {
                        fieldType: 'string',
                        fieldName: 'username',
                        filterType: 'equals',
                        value: username,
                    },
                ],
            },
        };

        return this.httpClient.post<DDDEntityResponseDto<{ client: DDDClientAggregateRole[] }>>(url, body).pipe(
            tap(res => this.checkResponse(res)),
            map(result => result.data.client?.[0])
        );
    }

    selectEntity(type: string, params: SelectEntityParams)
        : Observable<DDDEntityResponseDto<{ [type: string]: DDDEntity[] }>> {

        const url = `${this.urlPrefix}/entity/select/${type}`;

        const additionalAggregators = params.aggregators?.map(type => ({ type })) ?? [];

        const body: DDDEntitySelectRequestDto = {
            aggregators: [{ type: 'flow' }].concat(additionalAggregators),
            pagination: params.pagination,
            sorting: params.sorting,
            filters: params.filters,
        };

        return this.httpClient.post<DDDEntityResponseDto<{ [type: string]: DDDEntity[] }>>(url, body)
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    selectNestedEntity(
        parentType: string,
        parentSelectParams: SelectEntityParams,
        nestedType: string,
        nestedAggregatorTypes: string[] = []
    ): Observable<DDDEntity[]> {
        const url = `${this.urlPrefix}/entity/select/${parentType}`;

        const nestedAggregators: DDDEntitySelectAggregator[] = nestedAggregatorTypes?.map(type => ({ type })) ?? [];
        const parentAggregators: DDDEntitySelectAggregator[] = [
            {
                type: nestedType,
                aggregators: [{ type: 'flow' }].concat(nestedAggregators),
            },
        ];

        const body: DDDEntitySelectRequestDto = {
            aggregators: parentAggregators,
            filters: parentSelectParams.filters,
        };

        return this.httpClient.post<DDDEntityResponseDto<{ [type: string]: DDDEntity[] }>>(url, body)
            .pipe(
                tap(res => this.checkResponse(res)),
                map(res => res.data[parentType]),
                map(parentEntities => parentEntities.reduce(
                    (total, parentEntity) => total.concat(parentEntity[nestedType]),
                    []
                ))
            );
    }

    getEntities(type: string, body: DDDEntitySelectRequestDto) {
        const url = `${this.urlPrefix}/entity/select/${type}`;

        return this.httpClient.post<DDDEntityResponseDto<{ [type: string]: DDDEntity[] }>>(url, body)
            .pipe(tap(res => this.checkResponse(res) ));
    }

    getEntity(type: string, entityId: string, aggregators: string[] = []): Observable<DDDEntity> {
        const url = `${this.urlPrefix}/entity/select/${type}`;

        const additionalAggregators = aggregators?.map(type => ({ type })) ?? [];

        const body: DDDEntitySelectRequestDto = {
            aggregators: [{ type: 'flow' }].concat(additionalAggregators),
            filters: {
                ids: [entityId],
            },
        };

        return this.httpClient.post<DDDEntityResponseDto<{ [type: string]: DDDEntity[] }>>(url, body)
            .pipe(
                tap(res => this.checkResponse(res)),
                map(result => result.data[type]),
                map(entities => entities[0])
            )
    }

    updateStatus(entityId: string, status: string): Observable<DDDEntityUpdateStatusResponseDto> {
        const url = `${this.urlPrefix}/entity/${entityId}/status`;

        return this.httpClient.put<DDDEntityUpdateStatusResponseDto>(url, { status })
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    deleteEntity(entityId: string): Observable<DDDDeleteEntityResponseDto> {
        const url = `${this.urlPrefix}/entity/${entityId}`;
        return this.httpClient.delete<DDDDeleteEntityResponseDto>(url)
            .pipe(
                tap(res => this.checkResponse(res))
            );
    }

    fetchFineTicket(id: string) {
        const url = `/bmo/fines/${id}/ticket`;
        return this.httpClient.get(url, {
            responseType: 'blob',
            observe: 'response',
        });
    }

    getEntityTypeCount(entityType: string): Observable<{
        entityType: string;
        count: number;
    }> {
        return this.selectEntity(entityType, {
            aggregators: [],
            pagination: {
                onPage: 1,
                page: 0,
            },
        }).pipe(
            map(res => ({
                entityType,
                count: res.total,
            }))
        );
    }

    checkResponse(response: DDDBaseResponseDto): void {
        if (response?.success === false) {
            const message = response.errors?.map(e => e.message).join(' \n');
            this.messageBarService.warn(message, 0);
            throw new Error(message);
        }
    }
}

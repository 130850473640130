import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HideFullMenuAction, ShowFullMenuAction, ToggleFullMenuAction} from './layout.state.actions';

const INITIAL_LAYOUT_STATE = {
    fullMenu: false,
    panel: true,
};

interface LayoutStateModel {
    fullMenu: boolean
    panel: boolean;
}

@State<LayoutStateModel>({
    name: 'layout',
    defaults: INITIAL_LAYOUT_STATE
}) @Injectable()
export class LayoutState {

    @Selector()
    public static panel(state: LayoutStateModel): boolean {
        return state.panel;
    }

    @Selector()
    public static fullMenu(state: LayoutStateModel): boolean {
        return state.fullMenu;
    }

    @Action(ShowFullMenuAction)
    showFullMenuAction({patchState}: StateContext<LayoutStateModel>) {
        patchState({fullMenu: true});
    }

    @Action(HideFullMenuAction)
    hideFullMenuAction({patchState}: StateContext<LayoutStateModel>) {
        patchState({fullMenu: false});
    }

    @Action(ToggleFullMenuAction)
    toggleFullMenuAction({getState, patchState}: StateContext<LayoutStateModel>) {
        const {fullMenu} = getState();
        patchState({fullMenu: !fullMenu});
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetRequestParams, PostRequestParams } from '../interface/monitoring.service.interface';
import { VehiclesMonitoringRequestDto } from '../dto/vehicles-monitoring.request.dto';
import { VehiclesMonitoringResponseDto } from '../dto/vehicles-monitoring.response.dto';
import { HelperUtil } from '@shared/helper.util';
import { MonitoringCarEventsRequestDto } from '../dto/monitoring-car-events.request.dto';
import { MonitoringCarEventsResponseDto } from '../dto/monitoring-car-events.response.dto';

@Injectable()
export class MonitoringQueryService {
    constructor(
        private httpClient: HttpClient
    ) { }

    sendGetRequest({ url, options }: GetRequestParams): Observable<any> {
        return this.httpClient.get<any>(url, options);
    }

    sendPostRequest({ url, body }: PostRequestParams): Observable<any> {
        return this.httpClient.post<any>(url, body);
    }

    /**
     * @deprecated use /bmo/car/status/info with bmo entities
     * @param request 
     * @returns 
     */
    getMonitoringVehicles(request: VehiclesMonitoringRequestDto): Observable<VehiclesMonitoringResponseDto> {
        const url = '/mta/vehicles/monitoring';

        const params = HelperUtil.buildHttpParams(request);

        return this.httpClient.get<VehiclesMonitoringResponseDto>(url, { params });
    }

    getCarEvents(request: MonitoringCarEventsRequestDto): Observable<MonitoringCarEventsResponseDto> {
        const url = '/mta/carv2/carEvents';
        return this.httpClient.post<MonitoringCarEventsResponseDto>(url, request);
    }
}



export const APP_URL_MAP = {
    home: {
        url: '',
        name: 'Главная',
    },
    cars: {
        url: 'cars',
        name: 'Автомобили',
    },
    car: {
        url: ':id',
        name: 'Автомобиль',
    },
    monitoring: {
        url: 'monitoring',
        name: 'Мониторинг',
    },
    operators: {
        url: 'operators',
        name: 'Операторы',
    },
    operatorCreator: {
        url: 'new',
        name: 'Создание оператора',
    },
    operatorEditor: {
        url: 'edit',
        name: 'Профиль оператора',
    },
    login: {
        url: 'login',
        name: 'Авторизация',
    },
    drivers: {
        url: 'drivers',
        name: 'Водители',
    },
    driverEditor: {
        url: 'driver',
        name: 'Редактировать водителя',
    },
    fines: {
        url: 'fines',
        name: 'Штрафы',
    },
    fine: {
        url: ':id',
        name: 'Штраф',
    },
    finesMap: {
        url: 'map',
        name: 'Штрафы (места с вероятностью получения)',
    },
    profile: {
        url: 'profile',
        name: 'Мой профиль',
    },
    profileImpersonation: {
        url: 'impersonation',
        name: 'Имперсонация',
    },
    forgotPassword: {
        url: 'forgot',
        name: 'Восстановление пароля',
    },
    passwordRestoration: {
        url: 'password-restore',
        name: 'Восстановление пароля',
    },
    profileSettings: {
        url: 'settings',
        name: 'Настройки',
    },
    ggm: {
        url: 'ggm',
        name: 'GGM',
    },
    notifications: {
        url: 'notifications',
        name: 'Уведомления'
    },
    geozones: {
        url: 'geozones',
        name: 'Геозоны',
    },
    feedback: {
        url: 'feedback',
        name: 'Обратная связь',
    },
};

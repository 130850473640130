import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DDDLayoutQueryService } from "@app/core/ddd-layout/services/ddd-layout-query.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CarUpdateSimIdsRequestDto } from "../dto/car-update-sim-ids.request.dto";
import { CarUpdateSimIdsResponseDto } from "../dto/car-update-sim-ids.response.dto";
import { CarUpdateInfoByWAResponseDto } from "../dto/car-update-info-by-wa.response.dto";
import { CarUpdateInfoBySheetsResponseDto } from "../dto/car-update-info-by-sheets.response.dto";
import { CarStatusInfoRequestDto } from "../dto/car-status-info.request.dto";
import { CarStatusInfoResponseDto } from "../dto/car-status-info.response.dto";

@Injectable()
export class CarsBmoQueryService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly dddQueryService: DDDLayoutQueryService
    ) { }

    updateSimIds(dto: CarUpdateSimIdsRequestDto): Observable<CarUpdateSimIdsResponseDto> {
        return this.httpClient.post('/bmo/car/update/sim-id', dto).pipe(
            tap(res => this.dddQueryService.checkResponse(res))
        );
    }

    updateCarInfoByWA(id: string): Observable<CarUpdateInfoByWAResponseDto> {
        return this.httpClient.post('/bmo/car/update/info', { id }).pipe(
            tap(res => this.dddQueryService.checkResponse(res))
        );
    }

    updateCarInfoBySheets(id: string): Observable<CarUpdateInfoBySheetsResponseDto> {
        return this.httpClient.post(`/bmo/car/${id}`, {}).pipe(
            tap(res => this.dddQueryService.checkResponse(res))
        );
    }

    getCarsStatusInfo(dto: CarStatusInfoRequestDto): Observable<CarStatusInfoResponseDto> {
        return this.httpClient.post<CarStatusInfoResponseDto>('/bmo/car/status/info', dto);
    }
}

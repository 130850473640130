import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MonitoringParkStatisticsResponse } from "../interface/monitoring-park-statistics.response";
import { MonitoringVehicleStatusesResponse } from "../interface/monitoring-vehicle-statuses.response";

@Injectable({
    providedIn: 'root'
})
export class MonitoringStatisticsQueryService {
    constructor(private readonly httpClient: HttpClient) {
    }

    getVehicleStatuses(): Observable<MonitoringVehicleStatusesResponse> {
        const url = `/bmo/monitoring/vehicleStatus`;
        return this.httpClient.get<MonitoringVehicleStatusesResponse>(url);
    }

    getParkStatistics(): Observable<MonitoringParkStatisticsResponse> {
        const url = `/bmo/monitoring/parkStats`;
        return this.httpClient.get<MonitoringParkStatisticsResponse>(url);
    }
}
